import React, { useContext, useRef, useEffect, useCallback } from 'react';
import * as S from './SwipeableContainer.styles';
import FunnelContext from '../../context/FunnelContext';

const SwipeableContainer = props => {
  const {
    horizontal = true,
    vertical = false,
    maxHorizontalSwipe = 250,
    maxVerticalSwipe = 250,
    children
  } = props;

  const swipeableRef = useRef(null);

  const { extraObjects, setExtraObjects } = useContext(FunnelContext);

  const mouseMoveHandler = useCallback(
    e => {
      if (swipeableRef.current.style.cursor === 'grabbing') {
        const pos = extraObjects.mousePosition;

        // How far the mouse has been moved
        const dx = e.clientX - pos.x;
        const dy = e.clientY - pos.y;

        // Scroll the element
        if (vertical) {
          const topMargin = pos.top - dy;
          swipeableRef.current.style.marginTop = `${
            Math.abs(topMargin) > maxVerticalSwipe
              ? topMargin < 0
                ? -maxVerticalSwipe
                : maxVerticalSwipe
              : topMargin
          }px`;
        }
        if (horizontal) {
          const leftMargin = pos.left + dx;
          swipeableRef.current.style.marginLeft = `${
            Math.abs(leftMargin) > maxHorizontalSwipe
              ? leftMargin < 0
                ? -maxHorizontalSwipe
                : maxHorizontalSwipe
              : leftMargin
          }px`;
        }
      }
    },
    [
      extraObjects,
      horizontal,
      vertical,
      swipeableRef,
      maxHorizontalSwipe,
      maxVerticalSwipe
    ]
  );

  const mouseDownHandler = useCallback(
    e => {
      setExtraObjects(
        Object.assign(extraObjects, {
          mousePosition: {
            left: parseInt(swipeableRef.current.style.marginLeft) || 0,
            top: parseInt(swipeableRef.current.style.marginTop) || 0,
            x: e.clientX,
            y: e.clientY
          }
        })
      );
      swipeableRef.current.style.cursor = 'grabbing';
      swipeableRef.current.style.userSelect = 'none';
      swipeableRef.current.addEventListener('mousemove', mouseMoveHandler);
    },
    [swipeableRef, mouseMoveHandler, extraObjects, setExtraObjects]
  );

  const mouseUpHandler = useCallback(
    e => {
      swipeableRef.current.style.cursor = 'grab';
      swipeableRef.current.style.removeProperty('user-select');
      swipeableRef.current.removeEventListener('mousemove', mouseMoveHandler);
    },
    [swipeableRef, mouseMoveHandler]
  );

  useEffect(() => {
    const ele = swipeableRef.current;
    ele.addEventListener('mousedown', mouseDownHandler);
    ele.addEventListener('mouseup', mouseUpHandler);
    return () => {
      ele.removeEventListener('mousedown', mouseDownHandler);
      ele.removeEventListener('mouseup', mouseUpHandler);
    };
  }, [swipeableRef, mouseDownHandler, mouseUpHandler]);

  return (
    <S.Container>
      <S.Swipeable ref={swipeableRef}>{children}</S.Swipeable>
    </S.Container>
  );
};

export default SwipeableContainer;
